/* markerAnimation.css */

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  .blinking {
    animation: blinker 1s linear infinite;
  }
  